import { useEffect, useState } from 'react'

import useWidth from 'src/hooks/useWidth'

export const medias = [360, 768, 1024, 1440]
export const [mobile, tablet, desktopLG, desktopXL] = ['mobile', 'tablet', 'desktopLG', 'desktopXL']

const useDevice = () => {
  const windowWidth = useWidth(200)
  const [device, setDevice] = useState('')
  useEffect(() => {
    if (windowWidth < medias[1]) {
      setDevice(mobile)
    }
    if (windowWidth < medias[2]) {
      setDevice(tablet)
    }
    if (windowWidth < medias[3]) {
      setDevice(desktopLG)
    }
    if (windowWidth >= medias[3]) {
      setDevice(desktopXL)
    }
  }, [windowWidth])

  return device
}

export default useDevice
