import React from 'react'
import { useState, useEffect } from 'react'

import articlesJSON from 'src/assets/data/articles.json'
import { findCategoryBySlug } from 'src/services/category/category'
import { sub } from 'date-fns'

import CustomSelect from '../Select/CustomSelect'
import { OptionType } from '../Select/CustomSelect/type'

type FilterProps = {
  setQuery: (query: string) => string
  location: Location
}

const categories = Object.keys(articlesJSON).map((slug) => findCategoryBySlug(slug))
const categoriesForSelect = categories.map((category) => ({
  value: category?.id,
  label: category?.name,
}))
const optionsShow = [
  {
    label: 'Exibir: ',
    options: categoriesForSelect,
  },
]
const optionsOrderBy = [
  {
    label: 'Ordenados por: ',
    options: [
      { value: 'created_at', label: 'Mais Recentes' },
      { value: 'viewed', label: 'Mais lidas' },
      { value: 'comments', label: 'Mais Comentadas' },
      { value: 'shared', label: 'Mais Compartilhadas' },
    ],
  },
]
const optionsFilters = [
  {
    label: 'Filtrados: ',
    options: [
      { value: '', label: 'Em qualquer data' },
      { value: sub(new Date(), { hours: 1 }).toISOString(), label: 'Na última hora' },
      { value: sub(Date.now(), { days: 1 }).toISOString(), label: 'Nas últimas 24 horas' },
      { value: sub(Date.now(), { weeks: 1 }).toISOString(), label: 'Na última semana' },
      { value: sub(Date.now(), { months: 1 }).toISOString(), label: 'No último mês' },
      { value: sub(Date.now(), { years: 1 }).toISOString(), label: 'No último ano' },
    ],
  },
]

const Filter = ({ setQuery, location }: FilterProps) => {
  const [exibir, setExibir] = useState<OptionType[]>([])
  const [order, setOrder] = useState<OptionType>({ label: '', value: '' })
  const [time, setTime] = useState<OptionType>({ label: '', value: '' })

  const urlSearchParams = new URLSearchParams(location.search)
  const searchText = urlSearchParams.get('q')

  useEffect(() => {
    handleMountQuery()
  }, [searchText])

  function handleMountQuery() {
    const queryMounted = `(and '${searchText}' ${getQueryExibir()}${getQueryTime()})${getQueryOrder()}`
    setQuery(queryMounted)
  }
  function getQueryExibir() {
    if (!exibir.length) {
      return ''
    }
    return `(or ${exibir.map((item) => `category_id: '${item.value}'`).join(' ')})`
  }

  function getQueryOrder() {
    if (!order.value) {
      return `&sort=${optionsOrderBy[0].options[0].value} desc`
    }
    return `&sort=${order.value} desc`
  }
  function getQueryTime() {
    if (!time.value) {
      return ''
    }
    return `(and  published_at:['${time.value}','${new Date().toISOString()}'])`
  }

  useEffect(() => {
    handleMountQuery()
  }, [exibir, order, time])

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex mr-4">
        <CustomSelect
          name="exibir"
          options={optionsShow}
          label={optionsShow[0].label}
          type="checkbox"
          isMulti
          placeHolder="Todos os resultados"
          onChange={(value) => setExibir(value)}
        />
      </div>
      <div className="d-flex mr-4">
        <CustomSelect
          name="order"
          options={optionsOrderBy}
          label={optionsOrderBy[0].label}
          type="radio"
          isMulti={false}
          defaultValue={optionsOrderBy[0].options[0]}
          closeMenuOnSelect
          onChange={(value) => setOrder(value[0])}
        />
      </div>
      <div className=" d-flex mr-1">
        <CustomSelect
          name="time"
          options={optionsFilters}
          label={optionsFilters[0].label}
          type="radio"
          isMulti={false}
          defaultValue={optionsFilters[0].options[0]}
          closeMenuOnSelect
          onChange={(value) => setTime(value[0])}
        />
      </div>
    </div>
  )
}

export default Filter
