import React, { useRef, useEffect } from 'react'

import { components, MenuProps, OptionTypeBase } from 'react-select'
import { Button } from 'src/styles/button'

function useOutsideAlerter(ref: React.MutableRefObject<null>, handle: { (): unknown; (): void }) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: { target: unknown }) {
      if (ref.current) {
        if (!ref.current.contains(event.target)) {
          handle()
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handle, ref])
}

type OpenModalType = {
  setOpen: (open: boolean) => boolean
}

type CustomMenyType = OpenModalType & MenuProps<OptionTypeBase, boolean>

const Menu = ({ setOpen, children, ...props }: CustomMenyType) => {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, () => setOpen(false))
  return (
    <div ref={wrapperRef}>
      <>
        <components.Menu {...props}>{children}</components.Menu>
        <Button onClick={() => setOpen(false)} type="submit" className="max d-block d-lg-none my-4">
          Filtrar
        </Button>
      </>
    </div>
  )
}

export default Menu
