import categoriesJSON from 'src/assets/data/categories.json'

export const [VIDEO_GALLERY, COLUMNISTS, REPORTER] = ['VIDEO_GALLERY', 'COLUMNISTS', 'REPORTER']

export const filterGaleryAndColumnists = () =>
  categoriesJSON.filter((category) => {
    if (category.type !== 'GENERAL') {
      return true
    }
    return false
  })

export const findCategoryBySlug = (slug: string) =>
  categoriesJSON.find((category) => category.slug === slug)
