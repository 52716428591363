import React from 'react'

import { components } from 'react-select'

import { BottomSheet } from '@inter/inter-ui'

import { BottomSheetStyle } from './style'

const MenuPortal = ({ children, setOpen, isMobile, ...rest }) => {
  if (!isMobile) {
    return (
      <div>
        <components.MenuPortal {...rest}>{children}</components.MenuPortal>
      </div>
    )
  }
  return (
    <BottomSheetStyle>
      <BottomSheet
        onClose={() => {
          setOpen(false)
        }}
      >
        {children}
      </BottomSheet>
    </BottomSheetStyle>
  )
}

export default MenuPortal
