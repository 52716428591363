import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { gray, white, grayscale } from 'src/styles/colors'

const Radio = styled.div`
  display: flex;
  .form-label-radio {
    align-items: center;
  }
  input[type='radio'] {
    visibility: hidden;
    position: absolute;
  }
  input[type='radio'] + label {
    position: relative;
    cursor: pointer;
  }

  .before {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    content: ' ';
    display: inline-block;
    vertical-align: baseline;
    transition: 0.3s;
    border: 2px solid ${grayscale[300]};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checked {
    border-color: ${(props) => props.theme.colors.primary};
  }
  .after {
    background-color: ${(props) => props.theme.colors.primary};
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  label {
    white-space: nowrap;
  }
`

export const Checkbox = styled.div`
  margin: 20px 0;

  input {
    display: block;
  }

  .form-label-check {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.primary};

    &::before,
    &::after {
      transition: 0.3s;

      display: block;
      position: absolute;
      content: '';
    }

    &::before {
      border: 2px solid ${gray['500']};
      border-radius: 3px;
      height: 20px;
      left: 0;
      top: 0px;
      width: 20px;
    }

    a {
      &:hover {
        color: ${gray['600']};
      }
    }
  }

  .form-input-check {
    height: 0 !important;
    opacity: 0;
    width: auto;

    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
      &::before {
        background: ${(props) => props.theme.colors.primary};
        border: 1px solid ${(props) => props.theme.colors.primary};
      }
      &::after {
        left: 7px;
        top: 4px;
        width: 6px;
        height: 10px;
        border: solid ${white};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        @media ${device.tablet} {
          top: 4px;
        }
      }
    }
  }
`
export const BottomSheetStyle = styled.div`
  div {
    z-index: 10000;
  }
`

export default Radio
