import React from 'react'

import EmptyStateImg from 'src/assets/images/EmptyState.png'

type TextType = {
  searchText: string
}

const EmptyState = ({ searchText }: TextType) => (
  <div className="d-flex justify-content-center align-items-center flex-column">
    <img loading="lazy" src={EmptyStateImg} alt="pessoa com as mãos para cima" />
    <h2 className="text-center">
      Sem resultados para <br /> {searchText}
    </h2>
    <p>Tente novamente com outros termos</p>
  </div>
)

export default EmptyState
