import React from 'react'

import { GroupTypeBase, OptionProps, OptionTypeBase } from 'react-select'
import { grayscale } from 'src/styles/colors'

import Radio from './style'

const CustomOptionRadio = ({
  data,
  innerProps,
  isSelected,
}: OptionProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>) => (
  <div {...innerProps}>
    <div key={data.value}>
      <Radio className="my-2">
        <input defaultChecked={isSelected} type="radio" className="form-input-radio" />
        <div className={`before ${isSelected ? 'checked' : ''}`}>
          {isSelected ? <div className="after" /> : ''}
        </div>
        <label style={{ color: grayscale[400] }} className="form-label-radio d-flex  fs-14 lh-17">
          {data.label}
        </label>
      </Radio>
      <div className="px-5 d-block d-lg-none border" />
    </div>
  </div>
)

export default CustomOptionRadio
