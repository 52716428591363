/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react'

import { components } from 'react-select'
import OrangeIcon from 'src/components/Icon/OrangeDsIcon'
import { ThemeContext } from 'styled-components'

const ValueContainer = ({ setOpen, ...props }) => {
  const stringOptions = props
    .getValue()
    .map((option) => option.label)
    .join(', ')
  const { colors } = useContext(ThemeContext)

  return (
    <div
      role="none"
      onClick={() => setOpen(true)}
      onKeyPress={(event) => (event.key === 'Enter' ? setOpen(true) : '')}
    >
      <components.Control {...{ ...props, innerProps: {} }}>
        <p
          style={{
            color: colors.primary,
            fontWeight: 700,
            overflow: 'hidden',
            margin: 0,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '200px',
          }}
        >
          {stringOptions || props.selectProps.placeholder}
        </p>
        <OrangeIcon size="MD" icon="chevron-more" color={colors.primary} />
      </components.Control>
    </div>
  )
}

export default ValueContainer
