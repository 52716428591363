import React, { useEffect, useState } from 'react'
import '@inter/inter-ui/dist/inter-ui.css'

import { PageProps } from 'gatsby'
import axios from 'axios'
// Components
import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'
import Filter from 'src/components/Filter'
import EmptyState from 'src/components/EmptyState'
import Breadcrumb from 'src/components/Breadcrumb'
// Data
import PageTitle from 'src/components/PageTitle'
import ListArticles from 'src/components/ListArticles/ListArticles'
import { Article } from 'src/templates/category'
import LoadingIndicatorComponent from 'src/components/LoadingIndicator'
import { articleFields } from 'src/services/articles/articles'

import pageContext from './pageContext.json'

const Search = ({ location }: PageProps) => {
  const urlSearchParams = new URLSearchParams(location.search)
  const searchText = urlSearchParams.get('q')
  const [query, setQuery] = useState('')
  const [articles, setArticles] = useState<Article[]>([])
  const [start, setStart] = useState(0)
  const size = 25
  const [foundState, setFoundState] = useState(0)
  const [loading, setLoading] = useState(false)

  async function runSearch(text: string) {
    setLoading(true)
    setStart(0)
    const response = await axios.get(
      `${process.env.SEARCH_ENDPOINT}?q=${text}&q.parser=structured&size=${size}&start=${0}`,
    )
    const {
      data: {
        hits: { hit, found },
      },
    } = response
    setArticles(hit.map((item) => articleFields(item.fields)))
    setFoundState(found)
    setLoading(false)
  }

  async function loadMore() {
    if (start >= foundState) {
      return
    }
    setLoading(true)

    setStart(size + start)
    const response = await axios.get(
      `${process.env.SEARCH_ENDPOINT}?q=${query}&q.parser=structured&size=${size}&start=${
        start + size
      }`,
    )
    const {
      data: {
        hits: { hit },
      },
    } = response
    setArticles([...articles, ...hit.map((item) => articleFields(item.fields))])
    setLoading(false)
  }

  useEffect(() => {
    if (!query) {
      return
    }
    runSearch(query || '')
  }, [query])

  const path = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Busca',
      link: '/search',
    },
  ]

  return (
    <Layout pageContext={pageContext}>
      <SEO
        url={`${process.env.BASE_URL}/search`}
        title="Busca · Inter Invest"
        meta={[
          {
            name: 'description',
            content: 'Navegue por conteúdos sobre mais variados assuntos',
          },
          {
            property: 'og:title',
            content: 'Busca · Inter Invest',
          },
          {
            property: 'og:description',
            content: 'Navegue por conteúdos sobre mais variados assuntos',
          },
        ]}
        noIndex
      />
      <h1 className="sr-only">Buscar no Inter Invest</h1>
      <section
        id="compartilhe"
        className="d-flex py-3 justify-content-center align-items-center h-breadcrumb"
      >
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="d-none d-md-block mt-4">
                <Breadcrumb path={path} />
              </div>
              <div className="d-flex align-items-center">
                <h1 className="fs-24 lh-30 fw-600 mt-4 mb-0 mt-md-3 text-grayscale--500">
                  {`${pageContext.pageTitle} ${searchText}`}
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="border" />
          </div>
          <div className="col-12 pt-4" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <Filter query={query} setQuery={setQuery} location={location} />
          </div>
        </div>
      </section>
      <section>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-9">
            {loading ? (
              <div className="d-flex justify-content-center pt-5" style={{ minHeight: '50vh' }}>
                <LoadingIndicatorComponent />
              </div>
            ) : (
              <>
                {articles.length ? (
                  <ListArticles
                    loading={loading}
                    articles={articles}
                    showCategoryLabel
                    loadMore={() => loadMore}
                    size={start + size}
                  />
                ) : (
                  <EmptyState searchText={searchText} />
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Search
