import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

import Select from 'react-select'
import useMobile from 'src/hooks/useMobile'
import useDomReady from 'src/hooks/useDomReady'
import { ThemeContext } from 'styled-components'
import { GroupedOptionsType, ValueType, ActionMeta } from 'react-select'

import CustomOptionCheckBox from './CustomComponents/CustomOptionCheckBox'
import ValueContainer from './CustomComponents/ValueContainer'
import MenuPortal from './CustomComponents/MenuPortal'
import CustomOptionRadio from './CustomComponents/CustomOptionRadio'
import Menu from './CustomComponents/CustomMenu'
import { OptionType } from './type'

type CustomSelect = {
  name: string
  options: GroupedOptionsType<OptionType>
  label: string
  type: string
  isMulti: boolean
  defaultValue?: OptionType
  placeHolder?: string
  closeMenuOnSelect?: boolean
  onChange?: (value: ValueType<OptionType, boolean>, actionMeta: ActionMeta<OptionType>) => void
}

const CustomSelect = ({
  options,
  label,
  type,
  isMulti,
  defaultValue,
  placeHolder,
  closeMenuOnSelect,
  name,
  onChange,
}: CustomSelect) => {
  const [open, setOpen] = useState(false)
  const selectReference = useRef(null)

  const isMobile = useMobile()
  const domReady = useDomReady()

  const { colors } = useContext(ThemeContext)
  useEffect(() => {
    if (!open) {
      onChange ? onChange(selectReference?.current?.select.getValue(), 'action') : false
    }
  }, [onChange, open])

  const customStyles = {
    groupHeading: () => ({
      color: colors.primary,
      fontSize: '14px',
      fontWeight: 700,
    }),
    valueContainer: (styles) => ({
      ...styles,
      flexWrap: 'nowwrap',
      maxWidth: '200px',
    }),
    control: () => ({
      border: 'unset',
      display: 'flex',
    }),
    menu: () => ({
      padding: '0 2rem 0 1rem',
      border: `1px solid ${colors.primary}`,
      backgroundColor: 'white',
      borderRadius: '8px',
      position: 'absolute',
      zIndex: '1',
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: '80vh',
    }),
    placeholder: () => ({
      color: colors.primary,
      fontWeight: '700',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      color: colors.primary,
    }),
    singleValue: () => ({
      color: colors.primary,
      fontWeight: '600',
    }),
  }
  const customStylesMobile = {
    groupHeading: () => ({
      color: colors.primary,
      fontSize: '14px',
      fontWeight: 700,
    }),
    valueContainer: (styles) => ({
      ...styles,
      flexWrap: 'nowwrap',
      maxWidth: '200px',
    }),
    control: () => ({
      border: 'unset',
      display: 'flex',
    }),
    menu: () => ({
      position: 'relative',
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: '80vh',
    }),
    placeholder: () => ({
      color: colors.primary,
      fontWeight: '700',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      color: colors.primary,
    }),
    singleValue: () => ({
      color: colors.primary,
      fontWeight: '600',
    }),
  }

  function getBodyDocument() {
    if (!domReady) {
      return null
    }
    return document.getElementsByTagName('body')[0]
  }

  function getCustomType() {
    if (type === 'radio') {
      return CustomOptionRadio
    }
    return CustomOptionCheckBox
  }

  return (
    <>
      <label className="d-none d-lg-block mr-2 text-nowrap" htmlFor="">
        {label}
      </label>
      <Select
        ref={selectReference}
        name={name}
        placeholder={placeHolder}
        isClearable={false}
        className="w-100"
        isMulti={isMulti}
        components={{
          MenuPortal: (props) => MenuPortal({ ...props, setOpen, isMobile }),
          Control: (props) => ValueContainer({ ...props, setOpen }),
          Option: getCustomType(),
          Menu: (props) => Menu({ setOpen, ...props }),
        }}
        hideSelectedOptions={false}
        isOptionSelected={() => false}
        closeMenuOnSelect={closeMenuOnSelect || false}
        isSearchable={false}
        options={options}
        styles={!isMobile ? customStyles : customStylesMobile}
        menuPortalTarget={getBodyDocument()}
        menuIsOpen={open}
        onMenuClose={() => {
          setOpen(false)
        }}
        onMenuOpen={() => setOpen(true)}
        defaultValue={defaultValue}
      />
    </>
  )
}

export default CustomSelect
