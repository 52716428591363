import React from 'react'

import { grayscale } from 'src/styles/colors'
import { GroupTypeBase, OptionProps, OptionTypeBase } from 'react-select'

import { Checkbox } from './style'

const CustomOptionCheckBox = ({
  data,
  innerProps,
  isSelected,
}: OptionProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>) => (
  <div {...innerProps}>
    <div key={data.value}>
      <Checkbox className="my-2 py-2 ">
        <input
          checked={isSelected}
          onChange={() => null}
          type="checkbox"
          className="form-input-check"
        />
        <label
          style={{ color: grayscale[400] }}
          className="form-label-check fs-14 lh-17 text-nowrap"
        >
          {data.label}
        </label>
      </Checkbox>
      <div className="px-5 d-block d-lg-none border" />
    </div>
  </div>
)
export default CustomOptionCheckBox
