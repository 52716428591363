import { useEffect, useState } from 'react'

import useDevice from './useDevice'

const useMobile = () => {
  const device = useDevice()
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setIsMobile(device === 'mobile')
  }, [device])

  return isMobile
}

export default useMobile
